.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.form-signin
{
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}
    

.has-error  {

    box-shadow: none;
    color: #dd4b39;
}


.has-error input  {

  border: 2px solid #dd4b39;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.scroll2::-webkit-scrollbar {
  width: 5px;
}
 
.scroll2::-webkit-scrollbar-thumb {
  /* background: #666;  */
  background:silver;  
}


.spinner-container{
  /* width:100%; */
  /* height:50vh; */
  /* text-align: center;
  padding-top:40vh; */

  height: 100%;
  width: 100%;
  position: fixed;
  text-align: center;
  z-index: 1;
  top: 52px;
  left: 0;
  background-color:transparent;
  overflow-x: hidden;
}

.spinner-sub-container{
  padding-top:40vh;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00a65a;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  display: inline-block
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}